import { DeviceConnectionState, DeviceModel } from "./DeviceModel";

export type DeviceModelAction = {
  type: "addCharge" | "updateConnectionState";
  chargeDelta?: number;
  newConnectionState?: DeviceConnectionState;
};

export default function deviceModelReducer(
  deviceState: DeviceModel,
  action: DeviceModelAction,
): DeviceModel {
  switch (action.type) {
    case "addCharge":
      if (action.chargeDelta === undefined) {
        throw new Error(
          "Cannot add charge to device; given charge value is undefined",
        );
      }
      if (action.chargeDelta !== 0) {
        return {
          ...deviceState,
          charge: Math.max(
            0,
            Math.min(100, deviceState.charge + action.chargeDelta),
          ),
        };
      }
      return deviceState;
    case "updateConnectionState":
      if (action.newConnectionState === undefined) {
        throw new Error(
          "Cannot update device connection state; given connection state is undefined",
        );
      }
      return {
        ...deviceState,
        connectionState: action.newConnectionState,
      };
    default:
      return deviceState;
  }
}
