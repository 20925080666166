import * as DateUtil from "date-arithmetic";
import { useEffect, useMemo, useReducer } from "react";
import { useGetNextChargeTargetTime } from "./ChargerModel";
import chargerModelReducer from "./ChargerModelReducer";
import useCalculateShouldCharge from "./ChargerPredictor";
import ChargerView from "./ChargerView";
import { DeviceModel, getCurrentChargeState } from "./DeviceModel";

export default function ChargerController({
  now,
  device,
  requestCharge,
}: {
  now: Date;
  device: DeviceModel;
  requestCharge: (shouldCharge: boolean) => void;
}) {
  const [charger, chargerDispatch] = useReducer(chargerModelReducer, {
    schedule: {
      targets: [{ percentage: 80, time: new Date(0, 0, 1, 7) }],
      chargeWindows: [
        { start: new Date(0, 0, 1, 2), end: new Date(0, 0, 1, 5) },
      ],
    },
    scheduleOverride: null,
    history: [...Array(4)].map((_, index) => ({
      percentage: (100 * device.charge) / device.capacity,
      time: DateUtil.subtract(now, 3 - index, "hours"),
    })),
    predictions: [],
  });

  const currentChargeState = useMemo(
    () => getCurrentChargeState(device, now),
    [device, now],
  );
  const getNextChargeTargetTime = useGetNextChargeTargetTime(
    charger.schedule.targets,
  );

  const calculateShouldCharge = useCalculateShouldCharge(device, charger);

  useEffect(() => {
    if (
      charger.history[charger.history.length - 1].time < currentChargeState.time
    ) {
      chargerDispatch({
        type: "addHistory",
        newHistoryItem: currentChargeState,
      });
    }
  }, [charger.history, currentChargeState]);

  // Keep predictions about the future charge state up to date
  useEffect(() => {
    chargerDispatch({
      type: "updatePredictions",
      device,
      currentChargeState,
      shouldCharge: calculateShouldCharge,
    });
  }, [calculateShouldCharge, currentChargeState, device]);

  // Request that the externally-managed charging state be updated to match user preferences
  useEffect(() => {
    const shouldCharge = calculateShouldCharge(now, currentChargeState);
    if (shouldCharge !== (device.connectionState === "charging")) {
      requestCharge(shouldCharge);
    }
  }, [calculateShouldCharge, device, currentChargeState, requestCharge, now]);

  // Handle three possible user inputs: boost, restore schedule, cancel schedule
  const handleBoost = () => {
    // Calculate a date representing 1 hour from the later of now and the existing boost end time
    const currentBoostEndTime =
      charger.scheduleOverride && charger.scheduleOverride.mode === "boosted"
        ? charger.scheduleOverride.endTime
        : now;
    const newBoostEndTime = DateUtil.add(currentBoostEndTime, 1, "hours");
    // Set the schedule override end time to 1 hour from the later of now and the existing boost end time
    chargerDispatch({
      type: "setScheduleOverride",
      newScheduleOverride: {
        mode: "boosted",
        endTime: newBoostEndTime,
      },
    });
  };
  const handleRestore = () => {
    // Remove the schedule override
    chargerDispatch({
      type: "setScheduleOverride",
      newScheduleOverride: null,
    });
  };
  const handleCancel = () => {
    // Reset the schedule override until the next charge target time has passed
    chargerDispatch({
      type: "setScheduleOverride",
      newScheduleOverride: {
        mode: "cancelled",
        endTime: DateUtil.add(getNextChargeTargetTime(now), 1, "minutes"),
      },
    });
  };

  // Cancel charge boosting if device connection is lost
  // Restore schedule when schedule override end time is reached
  useEffect(() => {
    if (
      (device.connectionState === "disconnected" &&
        charger.scheduleOverride?.mode === "boosted") ||
      (charger.scheduleOverride && now > charger.scheduleOverride.endTime)
    ) {
      handleRestore();
    }
  }, [now, device.connectionState, charger.scheduleOverride]);

  return (
    <ChargerView
      now={now}
      device={device}
      charger={charger}
      onBoostClick={handleBoost}
      onRestoreClick={handleRestore}
      onCancelClick={handleCancel}
    />
  );
}
