import { useState } from "react";
import { Button } from "react-bootstrap";
import { Game } from "./GameModel";
import { ScoreLine } from "./ScoreLineModel";
import ScoreLineView from "./ScoreLineView";

export default function ScoreboardView({ game }: { game: Game }) {
  const [showSubScores, setShowSubScores] = useState<boolean>(false);

  return (
    <div className="card h-100 w-auto">
      <div className="row w-auto">
        <div className="col-auto">
          {game.scoreboard.scoreLines.map(
            (scoreLine: ScoreLine, index: number) => {
              let scoreLinePosition: "top" | "middle" | "bottom" = "middle";
              switch (index) {
                case 0:
                  scoreLinePosition = "top";
                  break;
                case game.scoreboard.scoreLines.length - 1:
                  scoreLinePosition = "bottom";
                  break;
                default:
              }

              return (
                <ScoreLineView
                  key={index}
                  frames={scoreLine.frames}
                  highlightBowl={index === game.currentPlayerIndex}
                  highlightFrameIndex={game.currentFrameIndex}
                  scoreLinePosition={scoreLinePosition}
                  showSubScores={showSubScores}
                />
              );
            },
          )}
        </div>
        <div className="col-auto my-2">
          <Button onClick={() => setShowSubScores(!showSubScores)}>
            Toggle Subscores
          </Button>
        </div>
      </div>
    </div>
  );
}
