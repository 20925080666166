import { HitPinCount } from "./FrameModel";
import scoreLineReducer from "./ScoreLineReducer";
import { Scoreboard } from "./ScoreboardModel";

export type ScoreboardAction = {
  type: "scoreHitPins";
  hitPinCount: HitPinCount;
  playerIndex: number;
  frameIndex: number;
};

export default function scoreboardReducer(
  scoreboard: Scoreboard,
  action: ScoreboardAction,
): Scoreboard {
  switch (action.type) {
    case "scoreHitPins": {
      // Get a new score line for the given player index, with an updated score for the current frame, bowl combo
      const updatedScoreLine = scoreLineReducer(
        scoreboard.scoreLines[action.playerIndex],
        {
          type: "scoreHitPins",
          hitPinCount: action.hitPinCount,
          frameIndex: action.frameIndex,
        },
      );

      // Return a new scoreboard with an updated score line
      return {
        ...scoreboard,
        scoreLines: [
          ...scoreboard.scoreLines.slice(0, action.playerIndex),
          updatedScoreLine,
          ...scoreboard.scoreLines.slice(action.playerIndex + 1),
        ],
      };
    }
    default:
      return scoreboard;
  }
}
