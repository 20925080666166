import {
  getAdditiveScore,
  getBowlScoreSymbols,
  getCumulativeScore,
  getHitPinScore,
} from "./FrameController";
import { Frame, HitPinCount, castToFinalFrame, deepClone } from "./FrameModel";

export type FrameAction =
  | {
      type: "scoreHitPins";
      hitPinCount: HitPinCount;
    }
  | {
      type: "scoreAdditiveFrames";
      nextFrame: Frame | undefined;
      nextNextFrame: Frame | undefined;
    }
  | {
      type: "calculateScoreLineSubtotal";
      previousFrame: Frame | undefined;
    };

export default function frameReducer(frame: Frame, action: FrameAction): Frame {
  switch (action.type) {
    case "scoreHitPins": {
      // Generate a clone of the input frame
      const finalFrame = castToFinalFrame(frame);
      const updatedFrame: Frame = deepClone(frame);
      if (
        frame.hitPinCounts[0] !== null &&
        frame.hitPinCounts[0] + action.hitPinCount > 10 &&
        !finalFrame
      ) {
        throw new Error(
          `Cannot hit ${action.hitPinCount} pin(s) on 2nd bowl after hitting ${frame.hitPinCounts[0]} pin(s) on 1st bowl`,
        );
      }
      const isFirstBowl = frame.hitPinCounts[0] === null;
      const isSecondBowl = !isFirstBowl && frame.hitPinCounts[1] === null;
      const isThirdBowl =
        !isFirstBowl && !isSecondBowl && finalFrame?.extraHitPinCount === null;

      if (isFirstBowl) {
        // Record the 1st
        updatedFrame.hitPinCounts[0] = action.hitPinCount;
        // If this 1st bowl was a strike, and this frame is not a final frame
        if (action.hitPinCount === 10 && !finalFrame) {
          // Score 0 for the 2nd bowl (completing the frame)
          updatedFrame.hitPinCounts[1] = 0;
        }
      } else if (isSecondBowl) {
        // Record the 2nd bowl
        updatedFrame.hitPinCounts[1] = action.hitPinCount;
      } else if (isThirdBowl) {
        // Record the 3rd bowl
        // finalFrame !== undefined is a prerequisite for isThirdBowl === true
        finalFrame!.extraHitPinCount = action.hitPinCount;
      } else {
        throw new Error(
          `Cannot hit ${action.hitPinCount} pin(s) on frame; all bowls have been recorded`,
        );
      }

      // Insert updated hit pin score into the output frame
      updatedFrame.hitPinScore = getHitPinScore(updatedFrame);
      if (finalFrame) {
        finalFrame.hitPinCounts = updatedFrame.hitPinCounts;
        finalFrame.hitPinScore = updatedFrame.hitPinScore;
      }

      // Get the bowl score symbols for the output frame
      const [bowlScoreSymbols, extraBowlScoreSymbol] = getBowlScoreSymbols(
        finalFrame ?? updatedFrame,
      );

      // Insert updated bowl score symbols into the output frame
      updatedFrame.bowlScoreSymbols = bowlScoreSymbols;

      if (finalFrame) {
        if (extraBowlScoreSymbol === undefined) {
          throw new Error(
            "Extra bowl score symbol was not defined, even though this is a final frame",
          );
        }
        // Combine all of the updated values for the normal part of this final frame
        finalFrame.bowlScoreSymbols = updatedFrame.bowlScoreSymbols;
        // Update the extra bowl score symbol for the new final frame
        finalFrame.extraBowlScoreSymbol = extraBowlScoreSymbol!;
        return finalFrame;
      }

      // Return the new, updated frame
      return updatedFrame;
    }
    case "scoreAdditiveFrames": {
      // Return a new frame, with updated additive score
      return {
        ...frame,
        additiveScore: getAdditiveScore(
          frame,
          action.nextFrame,
          action.nextNextFrame,
        ),
      };
    }
    case "calculateScoreLineSubtotal":
      // Return a new frame, with updated additive score
      return {
        ...frame,
        scoreLineSubTotal: getCumulativeScore(frame, action.previousFrame),
      };
    default:
      return frame;
  }
}
