import { getRemainingPinCount as getFrameRemainingPinCount } from "./FrameController";
import { RecordedHitPinCount } from "./FrameModel";
import { ScoreLine, getNewScoreLine } from "./ScoreLineModel";

export type Scoreboard = {
  scoreLines: ScoreLine[];
};

export function getNewScoreboard(
  playerCount: number = 2,
  frameCount: number = 10,
): Scoreboard {
  return {
    scoreLines: [...Array(playerCount)].map(() => getNewScoreLine(frameCount)),
  };
}

export function getRemainingPinCount(
  scoreboard: Scoreboard,
  playerIndex: number,
  frameIndex: number,
): RecordedHitPinCount {
  const frame = scoreboard.scoreLines[playerIndex]?.frames[frameIndex];
  if (!frame) {
    return 0;
  }
  return getFrameRemainingPinCount(frame);
}

export function getPlayerCount(scoreboard: Scoreboard): number {
  return scoreboard.scoreLines.length;
}

export function getFrameCount(scoreboard: Scoreboard): number {
  if (getPlayerCount(scoreboard) === 0) {
    throw new Error("Invalid scoreboard; player count is zero");
  }
  if (!scoreboard.scoreLines[0]) {
    throw new Error("Invalid scoreboard; first score line is invalid");
  }
  return scoreboard.scoreLines[0].frames?.length ?? 0;
}
