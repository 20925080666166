import { Navigate, Route, Routes } from "react-router-dom";
import Bowling from "./Main";

export default function Router() {
  return (
    <Routes>
      <Route index element={<Bowling />} />
      <Route path="/*" element={<Navigate replace to="" />} />
    </Routes>
  );
}
