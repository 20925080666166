import { Scoreboard, getFrameCount, getNewScoreboard } from "./ScoreboardModel";

export type Game = {
  currentFrameIndex: number;
  currentPlayerIndex: number;
  scoreboard: Scoreboard;
};

export function getNewGame(
  playerCount: number = 2,
  frameCount: number = 10,
): Game {
  return {
    currentFrameIndex: 0,
    currentPlayerIndex: 0,
    scoreboard: getNewScoreboard(playerCount, frameCount),
  };
}

export function getHasStarted(game: Game): boolean {
  return (
    game.currentPlayerIndex + game.currentFrameIndex > 0 ||
    game.scoreboard.scoreLines[0]?.frames[0]?.hitPinCounts[0] !== null
  );
}

export function getIsFinished(game: Game): boolean {
  return game.currentFrameIndex >= getFrameCount(game.scoreboard);
}
