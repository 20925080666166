import { Frame } from "./FrameModel";
import FrameView from "./FrameView";

export default function ScoreLineView({
  frames,
  highlightBowl,
  highlightFrameIndex,
  scoreLinePosition,
  showSubScores = false,
}: {
  frames: Frame[];
  highlightBowl: boolean;
  highlightFrameIndex: number;
  scoreLinePosition: "top" | "middle" | "bottom";
  showSubScores?: boolean;
}) {
  return (
    <div className="row justify-content-center">
      {frames.map((frame: Frame, index: number) => (
        <FrameView
          key={index}
          frame={frame}
          frameHighlight={
            index === highlightFrameIndex ? scoreLinePosition : "none"
          }
          highlightCurrentBowl={index === highlightFrameIndex && highlightBowl}
          showSubScores={showSubScores}
        />
      ))}
    </div>
  );
}
