import { Frame, HitPinCount } from "./FrameModel";
import frameReducer from "./FrameReducer";
import { ScoreLine } from "./ScoreLineModel";

export type ScoreLineAction =
  | {
      type: "scoreHitPins";
      hitPinCount: HitPinCount;
      frameIndex: number;
    }
  | {
      type: "updateAdditiveScores";
    }
  | {
      type: "updateCumulativeScores";
    };

export default function scoreLineReducer(
  scoreLine: ScoreLine,
  action: ScoreLineAction,
): ScoreLine {
  switch (action.type) {
    case "scoreHitPins": {
      // Get a new frame for the given score line, with an updated score for the current bowl
      const updatedFrame = frameReducer(scoreLine.frames[action.frameIndex], {
        type: "scoreHitPins",
        hitPinCount: action.hitPinCount,
      });

      // Generate a new score line, inserting the updated frame
      let updatedScoreLine = {
        ...scoreLine,
        frames: [
          ...scoreLine.frames.slice(0, action.frameIndex),
          updatedFrame,
          ...scoreLine.frames.slice(action.frameIndex + 1),
        ],
      };

      // Recreate the new score line, adding updated frame additive scores
      updatedScoreLine = scoreLineReducer(updatedScoreLine, {
        type: "updateAdditiveScores",
      });

      // Recreate the score line, adding updated frame cumulative scores
      updatedScoreLine = scoreLineReducer(updatedScoreLine, {
        type: "updateCumulativeScores",
      });

      return updatedScoreLine;
    }
    case "updateAdditiveScores":
      // Return a new score line with the additive scores for all frames updated
      return {
        ...scoreLine,
        frames: scoreLine.frames.map((frame, frameIndex, frames) =>
          frameReducer(frame, {
            type: "scoreAdditiveFrames",
            nextFrame: frames[frameIndex + 1],
            nextNextFrame: frames[frameIndex + 2],
          }),
        ),
      };
    case "updateCumulativeScores":
      // Return a new score line with the cumulative scores for all frames updated
      return {
        ...scoreLine,
        frames: scoreLine.frames.reduce(
          (newFrames: Frame[], currentFrame: Frame) => [
            ...newFrames,
            frameReducer(currentFrame, {
              type: "calculateScoreLineSubtotal",
              previousFrame: newFrames[newFrames.length - 1],
            }),
          ],
          [],
        ),
      };
    default:
      return scoreLine;
  }
}
