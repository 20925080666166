import { Locale, formatRelative } from "date-fns";
import { enGB } from "date-fns/locale";
import { MouseEventHandler } from "react";
import { Button } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";
import {
  ChargerModel,
  getNextChargeStartPoint,
  getPeakChargePoint,
} from "./ChargerModel";
import { DeviceModel } from "./DeviceModel";

const formatRelativeLocale: { [key: string]: string } = {
  lastWeek: "HH:mm 'last' eeee",
  yesterday: "HH:mm 'yesterday'",
  today: "HH:mm 'today'",
  tomorrow: "HH:mm 'tomorrow'",
  nextWeek: "HH:mm 'on' eeee",
  other: "HH:mm",
};

const locale: Locale = {
  ...enGB,
  formatRelative: (token: string) => formatRelativeLocale[token],
};
function customFormatRelative(date: Date): string {
  return formatRelative(date, new Date(), { locale });
}

export default function ScheduleControls({
  device,
  charger,
  onBoostClick,
  onRestoreClick,
  onCancelClick,
}: {
  device: DeviceModel;
  charger: ChargerModel;
  onBoostClick: MouseEventHandler<HTMLButtonElement>;
  onRestoreClick: MouseEventHandler<HTMLButtonElement>;
  onCancelClick: MouseEventHandler<HTMLButtonElement>;
}) {
  function getButton(
    key: number,
    text: string,
    onClick: MouseEventHandler<HTMLButtonElement>,
    enabled: boolean = true,
    variant: Variant = "primary",
  ) {
    return (
      <div key={key} className="col-6 col-md-3">
        <Button
          className={`w-100 h-100 p-0 p-sm-2${
            enabled || !enabled ? "" : " border-dark border-5"
          }`}
          variant={enabled ? variant : `outline-${variant}`}
          onClick={onClick}
          disabled={enabled ? undefined : true}>
          {enabled ? (
            <span className="lead">{text}</span>
          ) : (
            <del className="lead">{text}</del>
          )}
        </Button>
      </div>
    );
  }
  function getBoostButton() {
    const nextSalientChargePoint = getPeakChargePoint(
      charger.predictions,
      true,
    );
    return getButton(
      1,
      `Boost${charger.scheduleOverride?.mode === "boosted" ? " more" : ""}`,
      onBoostClick,
      device.connectionState !== "disconnected" &&
        (nextSalientChargePoint?.percentage ?? 0) < 100,
      "info",
    );
  }
  function getRestoreButton() {
    return getButton(
      2,
      "Restore Schedule",
      onRestoreClick,
      true,
      charger.scheduleOverride?.mode === "boosted" ? "warning" : "success",
    );
  }
  function getCancelButton() {
    return getButton(
      3,
      "Cancel Schedule",
      onCancelClick,
      charger.scheduleOverride?.mode !== "cancelled",
      "danger",
    );
  }

  const buttons = [];
  switch (charger.scheduleOverride?.mode ?? null) {
    case "boosted":
      buttons.push(getBoostButton());
      buttons.push(getRestoreButton());
      break;
    case "cancelled":
      buttons.push(getRestoreButton());
      buttons.push(getCancelButton());
      break;
    case null:
      buttons.push(getBoostButton());
      buttons.push(getCancelButton());
      break;
    default:
  }

  let infoText = "";
  let infoTextDecorator = "";
  if (device.connectionState === "disconnected") {
    infoText = `${device.name} is not connected; ${
      charger.scheduleOverride
        ? "charge schedule cancelled"
        : "will charge on schedule"
    }`;
    infoTextDecorator = charger.scheduleOverride
      ? "border-danger"
      : "border-warning";
  } else {
    switch (charger.scheduleOverride?.mode) {
      case "boosted": {
        const nextSalientChargePoint = getPeakChargePoint(
          charger.predictions,
          true,
        );
        if (nextSalientChargePoint) {
          const chargeAmountText = `${Math.round(
            nextSalientChargePoint.percentage,
          )}%`;
          const chargeTimeText = customFormatRelative(
            nextSalientChargePoint.time,
          );
          if (device.connectionState === "charging") {
            infoText = `Boosting charge to ${chargeAmountText} by ${chargeTimeText}`;
          } else {
            infoText = `Charge boosting requested until ${chargeTimeText}`;
          }
        } else if (device.connectionState === "charging") {
          infoText = `${device.name} is charging now (boosting)`;
        } else {
          infoText = `${device.name} will start boosting charge shortly`;
        }
        infoTextDecorator += " border-info";
        break;
      }
      case "cancelled": {
        const nextSalientChargePoint = getNextChargeStartPoint(
          charger.predictions,
        );
        if (nextSalientChargePoint) {
          const chargeAmountText = `${Math.round(
            nextSalientChargePoint.percentage,
          )}%`;
          const chargeTimeText = customFormatRelative(
            nextSalientChargePoint.time,
          );
          infoText = `Charging cancelled until ${chargeTimeText} (${chargeAmountText} charged now)`;
        } else {
          infoText = `${device.name} charging cancelled`;
        }
        infoTextDecorator += " border-danger";
        break;
      }
      default: {
        const nextSalientChargePoint = getPeakChargePoint(charger.predictions);
        if (nextSalientChargePoint) {
          const chargeAmountText = `${Math.round(
            nextSalientChargePoint.percentage,
          )}%`;
          const chargeTimeText = customFormatRelative(
            nextSalientChargePoint.time,
          );
          if (device.connectionState === "charging") {
            infoText = `Charging on schedule to ${chargeAmountText} by ${chargeTimeText}`;
          } else {
            infoText = `Will charge on schedule to ${chargeAmountText} by ${chargeTimeText}`;
          }
        } else if (device.connectionState === "charging") {
          infoText = `${device.name} is charging as scheduled`;
        } else {
          infoText = `${device.name} will start charging as scheduled shortly`;
        }
        infoTextDecorator += " border-light";
        break;
      }
    }
  }

  return (
    <div className="card h-100">
      <div className="d-none d-md-block m-2">
        <div className="row g-4">
          <div className="col-6">
            <div className={`p-2 h-100 card ${infoTextDecorator}`}>
              <span className="my-auto">{infoText}</span>
            </div>
          </div>
          {buttons}
        </div>
      </div>
      <div className="d-block d-md-none card-body row g-2">
        <div className="col-12 col-md-5">
          <div className={`p-2 card ${infoTextDecorator}`}>
            <span className="my-auto">{infoText}</span>
          </div>
        </div>
      </div>
      <div className="d-block d-md-none card-footer">
        <div className="row">{buttons}</div>
      </div>
    </div>
  );
}
