import * as DateUtil from "date-arithmetic";
import { useCallback } from "react";

export type ChargerModel = {
  schedule: ChargeSchedule;
  scheduleOverride: ChargeScheduleOverrideOption;
  history: ChargeDataPoint[];
  predictions: ChargeDataPoint[];
};

export type ChargeDataPoint = { percentage: number; time: Date };

export type ChargeSchedule = {
  targets: ChargeDataPoint[];
  chargeWindows: TimeWindow[];
};

export type TimeWindow = { start: Date; end: Date };

export type ChargeScheduleOverrideOption = {
  mode: ChargeScheduleOverrideMode;
  endTime: Date;
} | null;

export type ChargeScheduleOverrideMode = "boosted" | "cancelled";

export function useGetNextChargeTargetTime(chargeTargets: ChargeDataPoint[]) {
  return useCallback(
    (after: Date, fallback: Date = DateUtil.add(after, 1, "day")) =>
      // Find the next charge target time
      chargeTargets.reduce(
        (nextTargetTime: Date, currentTarget: ChargeDataPoint) => {
          let targetTime = DateUtil.date(
            DateUtil.month(
              DateUtil.year(currentTarget.time, after.getFullYear()),
              after.getMonth(),
            ),
            after.getDate() - 1,
          );
          while (targetTime < after) {
            targetTime = DateUtil.add(targetTime, 1, "day");
          }
          if (targetTime < nextTargetTime) {
            return targetTime;
          }
          return nextTargetTime;
        },
        fallback,
      ),
    [chargeTargets],
  );
}

export function getMaxPercentageChargeDataPoint(
  dataPoints: ChargeDataPoint[],
): ChargeDataPoint | null {
  return dataPoints.reduce(
    (maxTarget: ChargeDataPoint | null, currentTarget: ChargeDataPoint) =>
      !maxTarget || currentTarget.percentage > maxTarget.percentage
        ? currentTarget
        : maxTarget,
    null,
  );
}

export function getPeakChargePoint(
  dataPoints: ChargeDataPoint[],
  useFirstPlateau: boolean = false,
): ChargeDataPoint | null {
  if (dataPoints.length === 0) {
    return null;
  }
  return dataPoints.reduce(
    (
      peakChargeInfo: { dataPoint: ChargeDataPoint; break: boolean },
      currentDataPoint: ChargeDataPoint,
    ) => {
      // If break was already required, leave the peak output alone
      if (useFirstPlateau && peakChargeInfo.break) {
        return peakChargeInfo;
      }
      // If current data point has a higher percentage, use that instead
      if (currentDataPoint.percentage > peakChargeInfo.dataPoint.percentage) {
        return {
          dataPoint: currentDataPoint,
          break: false,
        };
      }
      // If current data point the same or a lower percentage, but it is higher than the first percentage
      if (
        currentDataPoint.percentage <= peakChargeInfo.dataPoint.percentage &&
        peakChargeInfo.dataPoint.percentage > dataPoints[0].percentage
      ) {
        // Take the previous value, and make a note to break from further calculations
        return { ...peakChargeInfo, break: true };
      }
      return peakChargeInfo;
    },
    { dataPoint: dataPoints[0], break: false },
  ).dataPoint;
}

export function getNextChargeStartPoint(
  dataPoints: ChargeDataPoint[],
): ChargeDataPoint | null {
  if (dataPoints.length === 0) {
    return null;
  }
  return dataPoints.reduce(
    (
      startChargeInfo: { dataPoint: ChargeDataPoint; break: boolean },
      currentDataPoint: ChargeDataPoint,
    ) => {
      // If break was already required, leave the peak output alone
      if (startChargeInfo.break) {
        return startChargeInfo;
      }
      // If current data point has a higher percentage, use that instead
      if (currentDataPoint.percentage > startChargeInfo.dataPoint.percentage) {
        return {
          dataPoint: currentDataPoint,
          break: true,
        };
      }
      return { dataPoint: currentDataPoint, break: false };
    },
    { dataPoint: dataPoints[0], break: false },
  ).dataPoint;
}
