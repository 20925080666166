import {
  BowlScoreSymbol,
  Frame,
  HitPinCount,
  RecordedHitPinCount,
  castToFinalFrame,
  scoredSpareOrStrike,
  scoredStrike,
} from "./FrameModel";

export function getRemainingPinCount(frame: Frame): HitPinCount {
  // If this is the 1st bowl
  if (frame.hitPinCounts[0] === null) {
    // There must be 10 pins standing
    return 10;
  }
  const finalFrame = castToFinalFrame(frame);
  // If a strike was scored in the 1st bowl
  if (frame.hitPinCounts[0] === 10) {
    // If this is a normal frame
    if (!finalFrame) {
      // This frame is complete
      return 0;
    }
  }
  // If this is the 2nd bowl (and, per above, the 1st bowl was not a strike)
  if (frame.hitPinCounts[1] === null) {
    // If this is a normal frame, or the 1st bowl was not a strike
    if (!finalFrame || frame.hitPinCounts[0] !== 10) {
      // However many pins were not hit in the 1st bowl must remain
      return (10 - frame.hitPinCounts[0]) as HitPinCount;
    }
    // Otherwise, this is a final frame, and the 1st bowl was a strike
    // This awards a full pin reset for the 2nd bowl, so all 10 pins remain
    return 10;
  }
  // Otherwise, if this is a normal frame
  if (!finalFrame) {
    // Both bowls have been recorded, so this frame is complete
    return 0;
  }
  // Otherwise, this is would be the 3rd bowl of a final frame
  // If the 3rd bowl has been recorded
  if (finalFrame.extraHitPinCount !== null) {
    // All 3 bowls have been recorded, so this frame is complete
    return 0;
  }
  const bowl1And2Sum = frame.hitPinCounts[0] + frame.hitPinCounts[1];
  // If the 1st and 2nd bowls were both strikes, or the 1st and 2nd bowls together score a spare
  if (bowl1And2Sum === 20 || bowl1And2Sum === 10) {
    // 1st and 2nd bowl hit pins were [10, 10] or [n, 10 - n], for n < 10 (e.g. [4,6])
    // Either scenario results in a full pin reset for a 3rd bowl, so all 10 pins remain
    return 10;
  }
  // Otherwise, if the 1st bowl was a strike (and, per above, the 2nd bowl was not a strike)
  if (frame.hitPinCounts[0] === 10) {
    // 1st and 2nd bowl hit pins were [n, 10 - n], for n < 10 (e.g. [4,6])
    // Return the number of pins left standing after the 2nd bowl
    return (10 - frame.hitPinCounts[1]) as HitPinCount;
  }
  // Otherwise, do not award a 3rd bowl
  return 0;
}

export function getIsComplete(
  frame: Frame,
  partialForFinalFrame: boolean = false,
): boolean {
  return (
    getRemainingPinCount(frame) === 0 ||
    (partialForFinalFrame &&
      frame.hitPinCounts[0] !== null &&
      frame.hitPinCounts[1] !== null)
  );
}

function getBowlScoreSymbol(
  hitPinCount: RecordedHitPinCount,
  previousBowlHitPinCount: RecordedHitPinCount,
  isInFinalFrame: boolean,
): BowlScoreSymbol {
  switch (hitPinCount) {
    case 0:
      return previousBowlHitPinCount === 10 && !isInFinalFrame ? "X" : "-";
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10: {
      const twoBowlSum = (previousBowlHitPinCount ?? 0) + hitPinCount;
      if (twoBowlSum === 10) {
        if (previousBowlHitPinCount === null) {
          return isInFinalFrame ? "X" : " ";
        }
        return "/";
      }
      if (twoBowlSum === 20 || hitPinCount === 10) {
        return "X";
      }
      return hitPinCount;
    }
    case null:
    default:
      return " ";
  }
}

export function getBowlScoreSymbols(
  frame: Frame,
): [[BowlScoreSymbol, BowlScoreSymbol], BowlScoreSymbol | undefined] {
  const finalFrame = castToFinalFrame(frame);
  const symbol0: BowlScoreSymbol = getBowlScoreSymbol(
    frame.hitPinCounts[0],
    null,
    !!finalFrame,
  );
  const symbol1: BowlScoreSymbol = getBowlScoreSymbol(
    frame.hitPinCounts[1],
    frame.hitPinCounts[0],
    !!finalFrame,
  );
  const symbol2: BowlScoreSymbol | undefined =
    finalFrame &&
    getBowlScoreSymbol(
      finalFrame.extraHitPinCount,
      frame.hitPinCounts[1],
      true,
    );
  return [[symbol0, symbol1], symbol2];
}

export function getHitPinScore(frame: Frame): RecordedHitPinCount {
  // If this frame is not complete
  if (!getIsComplete(frame, true)) {
    // Do not give it a hit pin score
    return null;
  }

  // Return the sum of the 1st and 2nd bowls
  return ((frame.hitPinCounts[0] ?? 0) +
    (frame.hitPinCounts[1] ?? 0)) as RecordedHitPinCount;
}

export function getAdditiveScore(
  frame: Frame,
  nextFrame: Frame | undefined,
  nextNextFrame: Frame | undefined,
): number | null {
  // If this frame is not complete
  if (!getIsComplete(frame)) {
    // Do not give it an additive score
    return null;
  }
  // If this is a final frame
  const finalFrame = castToFinalFrame(frame);
  if (finalFrame) {
    // Simulate additive score using the extra hit pin count
    return finalFrame.extraHitPinCount;
  }

  // If this is not the last frame, and it scored either a spare or strike
  if (nextFrame && scoredSpareOrStrike(frame)) {
    // If this frame specifically scored a strike (not a spare)
    if (scoredStrike(frame)) {
      // If the next frame is not complete
      if (!getIsComplete(nextFrame, true)) {
        // Wait until the next frame (on which this frame's additive score depends) is complete
        return null;
      }
      // Take the hit pin count of the first bowl of the next frame
      let additiveScore = nextFrame.hitPinScore ?? 0;
      // If there is one more subsequent frame, and the next frame also scored a strike (double-strike)
      if (nextNextFrame && scoredStrike(nextFrame)) {
        // If the subsequent frame has not had its first bowl
        if (nextNextFrame.hitPinCounts[0] === null) {
          // Wait until the subsequent frame (on which this frame's additive score depends) is complete
          return null;
        }
        // Add the hit pin count of the first bowl of the subsequent frame
        additiveScore += nextNextFrame.hitPinCounts[0] ?? 0;
      }
      return additiveScore;
    }
    // Otherwise, this frame specifically scored a spare (not a strike)
    // If the next frame has not had its first bowl
    if (nextFrame.hitPinCounts[0] === null) {
      // Wait until the next frame (on which this frame's additive score depends) is complete
      return null;
    }
    // Otherwise, take the hit pin count of the first bowl of the next frame
    return nextFrame.hitPinCounts[0] ?? 0;
  }
  // Otherwise, this is the last frame or this frame did scored neither a spare nor a strike
  return 0;
}

export function getCumulativeScore(
  frame: Frame,
  previousFrame: Frame | undefined,
): number | null {
  // If this frame is not yet complete, do not give it a subtotal
  if (!getIsComplete(frame)) {
    return null;
  }
  // If this frame does not know its additive score yet, do not give it a subtotal
  if (frame.additiveScore === null && !castToFinalFrame(frame)) {
    return null;
  }
  // If there is no previous frame
  if (!previousFrame) {
    // Return this frame's hit pin score plus additive score
    return (frame.hitPinScore ?? 0) + (frame.additiveScore ?? 0);
  }
  // If the previous frame does not have a subtotal, do not give this frame a subtotal
  if (previousFrame.scoreLineSubTotal === null) {
    return null;
  }
  // Otherwise, combine the previous frame's subtotal with
  // this frame's hit pin score plus additive score
  return (
    previousFrame.scoreLineSubTotal +
    (frame.hitPinScore ?? 0) +
    (frame.additiveScore ?? 0)
  );
}
