import { useReducer } from "react";
import GameController from "./GameController";
import { getNewGame } from "./GameModel";
import gameReducer from "./GameReducer";

export default function Main({
  playerCount,
  frameCount,
}: {
  playerCount?: number;
  frameCount?: number;
}) {
  const [game, gameDispatch] = useReducer(
    gameReducer,
    getNewGame(playerCount, frameCount),
  );

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-4 col-sm-auto my-auto">
          <img src="logo192.png" width="100px" alt="Brand logo" />
        </div>
        <div className="col-8 col-sm-auto my-auto">
          <h1 className="display-3">Bowling</h1>
        </div>
      </div>
      <GameController game={game} gameDispatch={gameDispatch} />
    </div>
  );
}
