import * as DateUtil from "date-arithmetic";
import { useCallback, useMemo } from "react";
import {
  ChargeDataPoint,
  ChargerModel,
  TimeWindow,
  getMaxPercentageChargeDataPoint,
} from "./ChargerModel";
import { DeviceModel } from "./DeviceModel";

export default function useCalculateShouldCharge(
  device: DeviceModel,
  charger: ChargerModel,
) {
  const maxChargeTarget = useMemo(
    () => getMaxPercentageChargeDataPoint(charger.schedule.targets),
    [charger.schedule.targets],
  );

  return useCallback(
    (time: Date, lastDataPoint: ChargeDataPoint): boolean => {
      // If there are no charge targets, there is no reason to charge
      if (!maxChargeTarget) {
        return false;
      }
      // If fully charged, there can be no more charging
      if (lastDataPoint.percentage > 99.99) {
        return false;
      }
      // If boosting the charge, charge until the relevant time
      if (
        charger.scheduleOverride?.mode === "boosted" &&
        time < charger.scheduleOverride.endTime
      ) {
        return true;
      }
      // If the schedule is cancelled, do not charge until the relevant time
      if (
        charger.scheduleOverride?.mode === "cancelled" &&
        time < charger.scheduleOverride.endTime
      ) {
        return false;
      }

      const compareTime = new Date(0, 0, 1, time.getHours(), time.getMinutes());
      const lastDataPointRelativeTime = new Date(
        0,
        0,
        1,
        lastDataPoint.time.getHours(),
        lastDataPoint.time.getMinutes(),
      );
      const lastDataPointCharge =
        (device.capacity * lastDataPoint.percentage) / 100;
      // Check whether any of the charge targets require charging to start
      const targetsRequireCharge: boolean = charger.schedule.targets.reduce(
        (needsCharge: boolean, currentTarget: ChargeDataPoint) => {
          // If another target already requires charge, that is sufficient information
          if (needsCharge) {
            return true;
          }
          // If this target is already met, ignore it
          if (lastDataPoint.percentage > currentTarget.percentage) {
            return false;
          }
          let targetTime = DateUtil.subtract(currentTarget.time, 1, "day");
          while (targetTime < compareTime) {
            targetTime = DateUtil.add(targetTime, 1, "day");
          }
          const targetCharge =
            (device.capacity * currentTarget.percentage) / 100;
          const hoursUntilTargetTime = DateUtil.diff(
            lastDataPointRelativeTime,
            targetTime,
            "hours",
            true,
          );
          return (
            lastDataPointCharge + hoursUntilTargetTime * device.chargeRate <
            targetCharge
          );
        },
        false,
      );
      // If so, predict charging at the given time
      if (targetsRequireCharge) {
        return true;
      }
      // If any charge target might require charging in the future
      if (lastDataPoint.percentage < maxChargeTarget.percentage) {
        // Check whether any of the charge windows recommend charging
        const chargeWindowsRecommendCharge: boolean =
          charger.schedule.chargeWindows.reduce(
            (shouldCharge: boolean, currentWindow: TimeWindow) => {
              if (shouldCharge) {
                return true;
              }
              let targetTime = DateUtil.subtract(compareTime, 1, "day");
              while (targetTime < currentWindow.start) {
                targetTime = DateUtil.add(targetTime, 1, "day");
              }
              return targetTime < currentWindow.end;
            },
            false,
          );
        // If so, predict charging at the given time
        if (chargeWindowsRecommendCharge) {
          return true;
        }
      }
      // If nothing else, default to predicting a non-charging state
      return false;
    },
    [
      charger.schedule.chargeWindows,
      charger.schedule.targets,
      charger.scheduleOverride?.endTime,
      charger.scheduleOverride?.mode,
      device.capacity,
      device.chargeRate,
      maxChargeTarget,
    ],
  );
}
