import { DeviceModel } from "./DeviceModel";

export default function DeviceView({ device }: { device: DeviceModel }) {
  const chargeTime = new Date(
    0,
    0,
    0,
    0,
    Math.round((60 * device.capacity) / device.chargeRate),
  );
  const chargeTimeString = `${chargeTime.getHours()}h ${chargeTime.getMinutes()}m`;

  return (
    <div className="card h-100">
      <div className="card-body d-flex">
        <img
          className="card-img my-auto"
          src={device.imgRelativeURL}
          alt={`Current device: ${device.name}`}
        />
      </div>
      <div className="card-footer">
        <div className="row">
          <div className="col-12 col-lg-5 my-auto lead">{device.name}</div>
          <div className="col-12 col-lg-7">
            <div className="row h-100">
              <div className="col-12 col-sm-7 my-auto">
                <span className="row text-sm-end">
                  <small>Full Charge:</small>
                </span>
              </div>
              <div className="col-12 col-sm-5 my-auto">
                <span className="row text-center text-md-start">
                  <small>{chargeTimeString}</small>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
