import { Button } from "react-bootstrap";
import { HitPinCount } from "./FrameModel";
import { Game, getHasStarted, getIsFinished } from "./GameModel";
import ScoreboardView from "./ScoreboardView";

export default function GameView({
  game,
  isSimulating,
  cheatsAvailable,
  restart,
  toggleSimulation,
  bowlOnce,
}: {
  game: Game;
  isSimulating: boolean;
  cheatsAvailable: boolean[];
  restart: () => void;
  toggleSimulation: () => void;
  bowlOnce: (hitPins?: HitPinCount) => void;
}) {
  return (
    <>
      <div className="row justify-content-center">
        <ScoreboardView game={game} />
      </div>
      <div className="row justify-content-center my-2 g-1">
        <div className="col-auto">
          <Button
            className={isSimulating ? "btn-danger" : ""}
            onClick={() => toggleSimulation()}
            disabled={getIsFinished(game)}>
            {isSimulating ? "Pause Simulation" : "Simulate Game"}
          </Button>
        </div>
        <div className="col-auto">
          <Button
            onClick={() => bowlOnce()}
            disabled={isSimulating || getIsFinished(game)}>
            Bowl Once
          </Button>
        </div>
        <div className="col-auto">
          <Button onClick={() => restart()} disabled={!getHasStarted(game)}>
            Restart Game
          </Button>
        </div>
        <div className="col-auto">
          <div className="row g-0">
            <div className="col m-1">Cheat:</div>
            {cheatsAvailable.map((available, hitPinCount: number) => (
              <div key={hitPinCount} className="col">
                <Button
                  onClick={() => bowlOnce(hitPinCount as HitPinCount)}
                  disabled={!available}>
                  {hitPinCount}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
