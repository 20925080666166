import * as DateUtil from "date-arithmetic";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

export default function TimeController({
  now,
  setNow,
  intervalSeconds,
}: {
  now: Date;
  setNow: (newNow: Date) => void;
  intervalSeconds: number;
}) {
  const [timeScale, setTimeScale] = useState<number>(1);

  useEffect(() => {
    const interval = setInterval(
      () =>
        setNow(
          DateUtil.add(now, intervalSeconds * Math.sqrt(timeScale), "seconds"),
        ),
      (intervalSeconds * 1000) / Math.sqrt(timeScale),
    );
    return () => {
      clearInterval(interval);
    };
  }, [now, setNow, intervalSeconds, timeScale]);

  return (
    <div className="row w-100 h-100 g-1 my-1">
      <div className="col-12 col-sm-3 my-auto lead">
        <div className="row text-sm-end lead">
          <div>Time Simulator:</div>
        </div>
      </div>
      <div className="col-12 col-sm-9">
        <div className="row">
          <div className="col-auto">
            <Button
              className="btn-sm my-auto"
              onClick={() =>
                setTimeScale(timeScale <= 1024 ? timeScale * 4 : 1)
              }>{`Adjust Time Scale: ${timeScale}x`}</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
