import { Dispatch, useCallback, useEffect, useMemo, useState } from "react";
import { HitPinCount } from "./FrameModel";
import { Game, getIsFinished } from "./GameModel";
import { GameAction } from "./GameReducer";
import GameView from "./GameView";
import { getRemainingPinCount } from "./ScoreboardModel";

export default function GameController({
  game,
  gameDispatch,
}: {
  game: Game;
  gameDispatch: Dispatch<GameAction>;
}) {
  const [autoSimulateGame, setAutoSimulateGame] = useState<boolean>(false);

  // Calculate how many pins are standing for the next bowl
  const remainingPinCount = useMemo(
    () =>
      getRemainingPinCount(
        game.scoreboard,
        game.currentPlayerIndex,
        game.currentFrameIndex,
      ) ?? 0,
    [game.currentFrameIndex, game.currentPlayerIndex, game.scoreboard],
  );

  const bowlOnce = useCallback(
    (hitPinCount?: HitPinCount, isSimulated: boolean = false) => {
      // Disallow manual triggering of this action whilst the game is simulating
      if (autoSimulateGame && !isSimulated) {
        return;
      }
      // Record a bowl, hitting the specified number of pins, or a random remainder of pins
      gameDispatch({
        type: "recordPinsHit",
        hitPinCount:
          hitPinCount ??
          (Math.floor(Math.random() * (1 + remainingPinCount)) as HitPinCount),
      });
    },
    [autoSimulateGame, remainingPinCount, gameDispatch],
  );

  // If the simulation state has changed
  useEffect(() => {
    // If simulation was not requested, do nothing (return an empty cleanup function)
    if (!autoSimulateGame) {
      return () => null;
    }
    // If simulation was requested, start simulating bowls periodically
    const timer = setInterval(() => bowlOnce(undefined, true), 100);
    // Return a cleanup function
    return () => {
      // If there is an interval timer
      if (timer) {
        // Cancel it
        clearInterval(timer);
      }
    };
  }, [autoSimulateGame, bowlOnce]);

  // If the game state has changed
  useEffect(() => {
    // If the game has finished
    if (getIsFinished(game)) {
      // Stop simulating the game
      setAutoSimulateGame(false);
    }
  }, [game]);

  function restartGame() {
    // Stop simulating the game (in case it was being simulated)
    setAutoSimulateGame(false);
    // Restart the game
    gameDispatch({
      type: "restart",
    });
  }

  return (
    <GameView
      game={game}
      isSimulating={autoSimulateGame}
      cheatsAvailable={[...Array(11)].map(
        (_, hitPinCount) =>
          !autoSimulateGame &&
          !getIsFinished(game) &&
          hitPinCount <= remainingPinCount,
      )}
      restart={restartGame}
      toggleSimulation={() => setAutoSimulateGame(!autoSimulateGame)}
      bowlOnce={bowlOnce}
    />
  );
}
