import { getIsComplete } from "./FrameController";
import { HitPinCount } from "./FrameModel";
import { Game, getIsFinished, getNewGame } from "./GameModel";
import { getFrameCount, getPlayerCount } from "./ScoreboardModel";
import scoreboardReducer from "./ScoreboardReducer";

export type GameAction =
  | {
      type: "restart";
    }
  | {
      type: "recordPinsHit";
      hitPinCount: HitPinCount;
    };

// Allow the game reducer to be cached once the game is initialized
// This prevents constant derived values (player and line counts) to be cached
export default function gameReducer(game: Game, action: GameAction): Game {
  switch (action.type) {
    case "restart":
      return getNewGame(
        getPlayerCount(game.scoreboard),
        getFrameCount(game.scoreboard),
      );

    case "recordPinsHit": {
      const playerCount = game.scoreboard.scoreLines.length;
      if (playerCount === 0) {
        throw new Error("Cannot record hit pins; player count is zero");
      }

      if (getIsFinished(game)) {
        throw new Error("Cannot record pins hit; game has ended");
      }

      // Get a new scoreboard, with an updated score for the current player, frame, bowl combo
      const updatedScoreboard = scoreboardReducer(game.scoreboard, {
        type: "scoreHitPins",
        hitPinCount: action.hitPinCount,
        playerIndex: game.currentPlayerIndex,
        frameIndex: game.currentFrameIndex,
      });

      // Increment the bowl, player, and frame indicies as required to move to the next turn
      let nextFrameIndex = game.currentFrameIndex;
      let nextPlayerIndex = game.currentPlayerIndex;
      if (
        getIsComplete(
          updatedScoreboard.scoreLines[game.currentPlayerIndex].frames[
            game.currentFrameIndex
          ],
        )
      ) {
        nextPlayerIndex += 1;
      }

      if (nextPlayerIndex >= playerCount) {
        nextPlayerIndex = 0;
        nextFrameIndex += 1;
      }

      // Return a new game state with updated scoreboard and indices
      return {
        ...game,
        scoreboard: updatedScoreboard,
        currentFrameIndex: nextFrameIndex,
        currentPlayerIndex: nextPlayerIndex,
      };
    }
    default:
      return game;
  }
}
