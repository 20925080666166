import * as DateUtil from "date-arithmetic";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { DeviceConnectionState, DeviceModel } from "./DeviceModel";

export default function DeviceInterface({
  now,
  device,
  shouldCharge,
  addCharge,
  updateConnectionState,
}: {
  now: Date;
  device: DeviceModel;
  shouldCharge: boolean;
  addCharge: (chargeDelta: number) => void;
  updateConnectionState: (newConnectionState: DeviceConnectionState) => void;
}) {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isCharging, setIsCharging] = useState<boolean>(false);

  const then = useRef<Date>(now);

  const requestCharge = (askToCharge: boolean): boolean => {
    // TODO: Replace with API callback interface
    setTimeout(() => setIsCharging(askToCharge), 1000);
    // Indicate the success of the request
    return true;
  };

  useEffect(() => {
    if (isCharging) {
      addCharge(
        device.chargeRate * DateUtil.diff(then.current, now, "hours", true),
      );
    }
    then.current = now;
  }, [now, addCharge, isCharging, device.chargeRate]);

  useEffect(() => {
    requestCharge(shouldCharge);
  }, [shouldCharge]);

  useEffect(() => {
    if (!isConnected) {
      setIsCharging(false);
    }
  }, [isConnected]);

  useEffect(() => {
    const chargingState = isCharging ? "charging" : "connected";
    updateConnectionState(!isConnected ? "disconnected" : chargingState);
  }, [isConnected, isCharging, updateConnectionState]);

  useEffect(() => {
    function handleConnected() {
      setIsConnected(true);
    }
    function handleDisconnect() {
      setIsCharging(false);
      setIsConnected(false);
    }
    // function handleChargingStarted() {
    //   setIsCharging(true);
    // }
    // function handleChargingStopped() {
    //   setIsCharging(false);
    // }
    // TODO: Open API connection
    setTimeout(() => handleConnected(), 1000);
    return () => {
      // TODO: Close API connection
      handleDisconnect();
    };
  }, []);

  const chargeAdjustAmount = device.capacity / 30;

  return (
    <div className="row w-100 h-100 g-1 my-1">
      <div className="col-12 col-sm-3 my-auto lead">
        <div className="row text-sm-end lead">
          <div>Device Simulator:</div>
        </div>
      </div>
      <div className="col-12 col-sm-9">
        <div className="row">
          <div className="col-auto">
            <Button
              className="btn-sm btn-success"
              onClick={() => addCharge(chargeAdjustAmount)}>
              {`Charge +${Math.round(chargeAdjustAmount)}kWh`}
            </Button>
          </div>
          <div className="col-auto">
            <Button
              className="btn-sm btn-danger"
              onClick={() => addCharge(-chargeAdjustAmount)}>
              {`Charge -${Math.round(chargeAdjustAmount)}kWh`}
            </Button>
          </div>
          <div className="col-auto">
            <Button
              className={`btn-sm btn-${isConnected ? "success" : "danger"}`}
              onClick={() => setIsConnected(!isConnected)}>
              {`Toggle: ${isConnected ? "Connected" : "Disconnected"}`}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
