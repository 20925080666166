import { useCallback, useReducer, useState } from "react";
import ChargerController from "./ChargerController";
import DeviceInterface from "./DeviceInterface";
import { DeviceConnectionState } from "./DeviceModel";
import deviceModelReducer from "./DeviceModelReducer";
import TimeController from "./TimeController";

export default function Charger() {
  const [now, setNow] = useState<Date>(new Date());

  const [shouldCharge, requestCharge] = useState<boolean>(false);

  const [device, deviceDispatch] = useReducer(deviceModelReducer, {
    name: "Tesla Model X",
    imgRelativeURL: "img/charger/tesla.png",
    connectionState: "disconnected",
    charge: 60,
    capacity: 100,
    chargeRate: 17,
  });

  const addCharge = useCallback((chargeDelta: number) => {
    deviceDispatch({
      type: "addCharge",
      chargeDelta,
    });
  }, []);

  const updateConnectionState = useCallback(
    (newConnectionState: DeviceConnectionState) => {
      deviceDispatch({
        type: "updateConnectionState",
        newConnectionState,
      });
    },
    [],
  );

  return (
    <>
      <div className="navbar fixed-bottom card text-left">
        <TimeController now={now} setNow={setNow} intervalSeconds={5} />
        <DeviceInterface
          now={now}
          device={device}
          shouldCharge={shouldCharge}
          addCharge={addCharge}
          updateConnectionState={updateConnectionState}
        />
      </div>
      <ChargerController
        now={now}
        device={device}
        requestCharge={requestCharge}
      />
    </>
  );
}
