import { Frame, getNewFinalFrame, getNewFrame } from "./FrameModel";

export type ScoreLine = {
  frames: Frame[];
};

export function getNewScoreLine(frameCount: number = 10): ScoreLine {
  return {
    frames: [
      ...[...Array(frameCount - 1)].map(() => getNewFrame()),
      getNewFinalFrame(),
    ],
  };
}
