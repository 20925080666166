import { ChargeDataPoint } from "./ChargerModel";

export type DeviceModel = DeviceState & DeviceProperties;

export type DeviceState = {
  connectionState: DeviceConnectionState;
  charge: number; // Measured in kWh
};

export type DeviceProperties = {
  name: string;
  imgRelativeURL: string;
  capacity: number; // Measured in kWh
  chargeRate: number; // Measured in kWh/h = kW
};

export type DeviceConnectionState = "disconnected" | "connected" | "charging";

export function getCurrentChargeState(
  device: DeviceModel,
  now: Date,
): ChargeDataPoint {
  return {
    percentage: (100 * device.charge) / device.capacity,
    time: now,
  };
}
