import { Demo } from "./Demo";
import DemoCard from "./DemoCard";

export default function HomeLayout({ demos }: { demos: Demo[] }) {
  return (
    <div className="container text-center my-5">
      <div className="row g-2">
        <h1>Lewis Antony Jones - Tech Demos</h1>
      </div>
      <h4 className="row my-3 text-left">Please select a demo:</h4>
      <div className="row">
        {demos.map((demo, index) => (
          <DemoCard key={index} demo={demo} />
        ))}
      </div>
    </div>
  );
}
