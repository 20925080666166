import { MouseEventHandler } from "react";
import { ChargerModel } from "./ChargerModel";
import { DeviceModel } from "./DeviceModel";
import DeviceView from "./DeviceView";
import ScheduleControls from "./ScheduleControls";
import ScheduleGraph from "./ScheduleGraph";

export default function ChargerView({
  now,
  device,
  charger,
  onBoostClick,
  onRestoreClick,
  onCancelClick,
}: {
  now: Date;
  device: DeviceModel;
  charger: ChargerModel;
  onBoostClick: MouseEventHandler<HTMLButtonElement>;
  onRestoreClick: MouseEventHandler<HTMLButtonElement>;
  onCancelClick: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-4 col-sm-auto my-auto">
          <img src="logo192.png" width="100px" alt="Brand logo" />
        </div>
        <div className="col-8 col-sm-auto my-auto">
          <h1 className="display-3">Car Charge Manager</h1>
        </div>
      </div>
      <div className="row g-3">
        <div className="col-5 col-md-5 order-1 order-md-1">
          <DeviceView device={device} />
        </div>
        <div className="col-12 col-md-7 order-3 order-md-2">
          <ScheduleGraph
            now={now}
            schedule={charger.schedule}
            scheduleOverride={charger.scheduleOverride}
            currentChargePercentage={(100 * device.charge) / device.capacity}
            history={charger.history}
            predictions={charger.predictions}
          />
        </div>
        <div className="col-7 col-md-12 order-2 order-md-3">
          <ScheduleControls
            device={device}
            charger={charger}
            onBoostClick={onBoostClick}
            onRestoreClick={onRestoreClick}
            onCancelClick={onCancelClick}
          />
        </div>
      </div>
    </div>
  );
}
