import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";
import * as DateUtil from "date-arithmetic";
import { format } from "date-fns";
import { Line } from "react-chartjs-2";
import {
  ChargeDataPoint,
  ChargeSchedule,
  ChargeScheduleOverrideOption,
} from "./ChargerModel";

Chart.register(CategoryScale);

export default function ScheduleGraph({
  now,
  schedule,
  scheduleOverride,
  currentChargePercentage,
  history,
  predictions: prediction,
}: {
  now: Date;
  schedule: ChargeSchedule;
  scheduleOverride: ChargeScheduleOverrideOption;
  currentChargePercentage: number;
  history: ChargeDataPoint[];
  predictions: ChargeDataPoint[];
}) {
  const nowToNearestMinute = DateUtil.seconds(DateUtil.milliseconds(now, 0), 0);
  const min = DateUtil.subtract(
    DateUtil.subtract(
      nowToNearestMinute,
      nowToNearestMinute.getMinutes(),
      "minutes",
    ),
    2,
    "hours",
  );
  const max = DateUtil.add(min, 1, "day");
  const midnight = DateUtil.add(
    DateUtil.hours(DateUtil.minutes(nowToNearestMinute, 0), 0),
    1,
    "day",
  );
  const midnightYesterday = DateUtil.subtract(midnight, 1, "day");
  const midnightTomorrow = DateUtil.add(midnight, 1, "day");
  const addDay = (time: Date, day: Date, limitToRange: boolean = true) => {
    const date = DateUtil.add(
      day,
      time.getMinutes() + time.getHours() * 60 + (time.getDate() - 1) * 24 * 60,
      "minutes",
    );
    if (limitToRange) {
      if (date < min) {
        return min;
      }
      if (date > max) {
        return max;
      }
    }
    return date;
  };

  const data = {
    labels: [...Array(25)].map((_, index) => DateUtil.add(min, index, "hours")),
    datasets: [
      {
        label: "Now",
        data: [
          { x: now, y: 0 },
          { x: now, y: 100 },
        ],
        radius: 0,
        showLine: true,
        borderWidth: 2,
        borderColor: "rgba(0, 0, 0, 1)",
      },
      {
        label: "Charge Targets",
        data: schedule.targets.map((target: ChargeDataPoint) => {
          let targetTime = addDay(target.time, midnightYesterday, false);
          while (targetTime <= min) {
            targetTime = DateUtil.add(targetTime, 1, "day");
          }
          while (targetTime >= max) {
            targetTime = DateUtil.subtract(targetTime, 1, "day");
          }
          return {
            x: targetTime,
            y: target.percentage,
          };
        }),
        radius: 5,
        backgroundColor: "rgba(255, 215, 0, 1)",
        showLine: false,
      },
      {
        label: "Charge History",
        data: [
          ...history.map((dataPoint: ChargeDataPoint) => ({
            x: dataPoint.time > min ? dataPoint.time : min,
            y: dataPoint.percentage,
          })),
          {
            x: now,
            y: currentChargePercentage,
          },
        ],
        radius: 0,
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.5)",
        showLine: true,
      },
      {
        label: "Charge Prediction",
        data: [
          {
            x: now,
            y: currentChargePercentage,
          },
          ...prediction
            .filter(
              (dataPoint: ChargeDataPoint) =>
                dataPoint.time > now && dataPoint.time < max,
            )
            .map((dataPoint: ChargeDataPoint) => ({
              x: dataPoint.time,
              y: dataPoint.percentage,
            })),
        ],
        radius: 0,
        borderDash: [8, 6],
        borderWidth: 1.5,
        borderColor: `rgba(${
          scheduleOverride?.mode === "cancelled" ? 255 : 0
        }, ${scheduleOverride?.mode === "boosted" ? 127 : 0}, ${
          scheduleOverride?.mode === "boosted" ? 255 : 0
        }, ${scheduleOverride ? 1 : 0.5})`,
        showLine: true,
      },
      {
        label: "Charge Windows",
        data: schedule.chargeWindows.reduce(
          (
            dataList: { x: Date; y: number }[],
            currentChargeWindow: { start: Date; end: Date },
          ) => [
            ...dataList,
            { x: addDay(currentChargeWindow.start, midnightYesterday), y: 0 },
            {
              x: addDay(currentChargeWindow.start, midnightYesterday),
              y: 100,
            },
            { x: addDay(currentChargeWindow.end, midnightYesterday), y: 100 },
            { x: addDay(currentChargeWindow.end, midnightYesterday), y: 0 },
            { x: addDay(currentChargeWindow.start, midnight), y: 0 },
            { x: addDay(currentChargeWindow.start, midnight), y: 100 },
            { x: addDay(currentChargeWindow.end, midnight), y: 100 },
            { x: addDay(currentChargeWindow.end, midnight), y: 0 },
            { x: addDay(currentChargeWindow.start, midnightTomorrow), y: 0 },
            {
              x: addDay(currentChargeWindow.start, midnightTomorrow),
              y: 100,
            },
            { x: addDay(currentChargeWindow.end, midnightTomorrow), y: 100 },
            { x: addDay(currentChargeWindow.end, midnightTomorrow), y: 0 },
          ],
          [],
        ),
        radius: 0,
        showLine: false,
        fill: {
          target: "origin",
          above: "rgba(0, 255, 0, 0.25)",
        },
      },
    ],
  };

  return (
    <div className="card h-100 chart-container">
      <Line
        className="m-2 mt-1"
        data={data}
        options={{
          plugins: {
            title: {
              display: true,
              text: "Charge Schedule",
            },
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              type: "time",
              ticks: {
                callback: (value) => format(new Date(value), "HH:mm"),
              },
            },
            y: {
              suggestedMin: 0,
              suggestedMax: 100,
            },
          },
          animation: {
            duration: 200,
          },
        }}
      />
    </div>
  );
}
