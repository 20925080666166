import { Frame, castToFinalFrame, getBowlIndex } from "./FrameModel";

export default function FrameView({
  frame,
  frameHighlight,
  highlightCurrentBowl,
  showSubScores = false,
}: {
  frame: Frame;
  frameHighlight: "top" | "middle" | "bottom" | "none";
  highlightCurrentBowl: boolean;
  showSubScores?: boolean;
}) {
  let borders = "border border-3 border-warning ";
  switch (frameHighlight) {
    case "top":
      borders += "border-bottom-0";
      break;
    case "middle":
      borders += "border border-top-0 border-bottom-0";
      break;
    case "bottom":
      borders += "border border-top-0";
      break;
    default:
      borders = "";
      break;
  }

  const finalFrame = castToFinalFrame(frame);
  const bowlIndex = getBowlIndex(frame);

  const additiveScoreString = frame.additiveScore
    ? `+${frame.additiveScore}`
    : "";
  const subscoreStrings = [
    (finalFrame ? frame.hitPinCounts[0] : frame.hitPinScore) ?? "",
    finalFrame ? frame.hitPinCounts[1] ?? "" : additiveScoreString,
    finalFrame?.extraHitPinCount ?? "",
  ];

  return (
    <div className={`col-auto ${borders}`}>
      <div className="row">
        <div className="col-auto border border-primary m-1 lead">
          <div className="row">
            <div
              className={`col-auto p-3 ${
                highlightCurrentBowl && bowlIndex === 0 ? "bg-warning" : ""
              }`}>
              <div className="position-absolute translate-middle">
                {frame.bowlScoreSymbols[0]}
              </div>
            </div>
            <div
              className={`col-auto p-3 border-start border-bottom border-primary ${
                highlightCurrentBowl && bowlIndex === 1 ? "bg-warning" : ""
              }`}>
              <div className="position-absolute translate-middle">
                {frame.bowlScoreSymbols[1]}
              </div>
            </div>
            {finalFrame ? (
              <div
                className={`col-auto p-3 border-start border-bottom border-primary ${
                  highlightCurrentBowl && bowlIndex === 2 ? "bg-warning" : ""
                }`}>
                <div className="position-absolute translate-middle">
                  {finalFrame.extraBowlScoreSymbol}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          {showSubScores ? (
            <div className="row">
              <div className="col-auto p-3 border-top border-bottom">
                <div className="position-absolute translate-middle small">
                  <small>{subscoreStrings[0]}</small>
                </div>
              </div>
              <div className="col-auto p-3 border-start border-top border-bottom">
                <div className="position-absolute translate-middle small">
                  <small>{subscoreStrings[1]}</small>
                </div>
              </div>
              {finalFrame ? (
                <div className="col-auto p-3 border-start border-top border-bottom">
                  <div className="position-absolute translate-middle small">
                    <small>{subscoreStrings[2]}</small>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          <div className="row text-center">
            <div className="col-12 p-3 text-monospace">
              <div className="position-absolute translate-middle-y">
                {frame.scoreLineSubTotal ?? ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
