import * as DateUtil from "date-arithmetic";
import {
  ChargeDataPoint,
  ChargeScheduleOverrideOption,
  ChargerModel,
} from "./ChargerModel";
import { DeviceModel } from "./DeviceModel";

export type ChargerModelAction = {
  type: "setScheduleOverride" | "addHistory" | "updatePredictions";
  newScheduleOverride?: ChargeScheduleOverrideOption;
  newHistoryItem?: ChargeDataPoint;
  device?: DeviceModel;
  currentChargeState?: ChargeDataPoint;
  shouldCharge?: (time: Date, lastDataPoint: ChargeDataPoint) => boolean;
};

export default function chargerModelReducer(
  chargerState: ChargerModel,
  action: ChargerModelAction,
): ChargerModel {
  switch (action.type) {
    case "setScheduleOverride":
      if (action.newScheduleOverride === undefined) {
        throw new Error(
          "Cannot set schedule override on charger; given schedule override is undefined",
        );
      }
      return {
        ...chargerState,
        scheduleOverride: action.newScheduleOverride,
      };
    case "addHistory":
      if (action.newHistoryItem === undefined) {
        throw new Error(
          "Cannot add charge history to charger; given history item is undefined",
        );
      }
      if (
        chargerState.history.length === 0 ||
        action.newHistoryItem.time >
          chargerState.history[chargerState.history.length - 1].time
      ) {
        return {
          ...chargerState,
          history: [...chargerState.history, action.newHistoryItem],
        };
      }
      return {
        ...chargerState,
        history: [
          ...chargerState.history.splice(chargerState.history.length - 1, 1, {
            time: action.newHistoryItem.time,
            percentage: Math.max(
              action.newHistoryItem.percentage,
              chargerState.history[chargerState.history.length - 1].percentage,
            ),
          }),
        ],
      };
    case "updatePredictions": {
      if (action.device === undefined) {
        throw new Error(
          "Cannot update charger predictions; given device is undefined",
        );
      }
      if (action.currentChargeState === undefined) {
        throw new Error(
          "Cannot update charger predictions; given current charge state is undefined",
        );
      }
      if (action.shouldCharge === undefined) {
        throw new Error(
          "Cannot update charger predictions; given prediction function is undefined",
        );
      }
      const timeDeltaMinutes = 2;
      return {
        ...chargerState,
        predictions: [...Array((48 * 60) / timeDeltaMinutes)].reduce(
          (predictions) => {
            const checkTime =
              predictions.length > 0
                ? predictions[predictions.length - 1].time
                : action.currentChargeState!.time;
            const lastDataPoint: ChargeDataPoint = {
              time: checkTime,
              percentage:
                predictions.length > 0
                  ? predictions[predictions.length - 1].percentage
                  : action.currentChargeState!.percentage,
            };
            const willCharge = action.shouldCharge!(checkTime, lastDataPoint);
            return [
              ...predictions,
              {
                percentage: Math.min(
                  100,
                  (predictions.length > 0
                    ? predictions[predictions.length - 1].percentage
                    : (100 * action.device!.charge) / action.device!.capacity) +
                    (willCharge
                      ? (action.device!.chargeRate * timeDeltaMinutes) / 60
                      : 0),
                ),
                time: DateUtil.add(checkTime, timeDeltaMinutes, "minutes"),
              },
            ];
          },
          [],
        ),
      };
    }
    default:
      return chargerState;
  }
}
