import { Link } from "react-router-dom";
import { Demo } from "./Demo";

export default function DemoCard({ demo }: { demo: Demo }) {
  return (
    <div className="col-3">
      <Link to={demo.relativeURL} className="card h-100">
        <div className="card-body d-flex">
          <img className="card-img my-auto" src={demo.img} alt={demo.title} />
        </div>
        <div className="card-footer">
          <div className="my-auto lead">{demo.title}</div>
        </div>
      </Link>
    </div>
  );
}
