export type Frame = {
  hitPinCounts: [RecordedHitPinCount, RecordedHitPinCount]; // Actual numbers of pins hit in each bowl in this frame
  hitPinScore: RecordedHitPinCount; // Direct score from the pins hit in this frame
  additiveScore: number | null; // Indirectly score, added from subsequent frames, due to a strike or spare in this frame
  scoreLineSubTotal: number | null; // Accumulated score up to this frame in the score line
  bowlScoreSymbols: [BowlScoreSymbol, BowlScoreSymbol]; // Symbols used to represent the score from each bowl in this frame
};

export type FinalFrame = Frame & {
  extraHitPinCount: RecordedHitPinCount; // Actual number of pins hit in the bonus bowl of this final frame
  extraBowlScoreSymbol: BowlScoreSymbol; // Symbol used to represent the score from each bowl in this frame
};

export type HitPinCount = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export type RecordedHitPinCount = HitPinCount | null;

export type BowlScoreSymbol =
  | " "
  | "-"
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | "/"
  | "X";

export function getNewFrame(): Frame {
  return {
    hitPinCounts: [null, null],
    hitPinScore: null,
    additiveScore: null,
    scoreLineSubTotal: null,
    bowlScoreSymbols: [" ", " "],
  };
}

export function getNewFinalFrame(): FinalFrame {
  return {
    ...getNewFrame(),
    extraHitPinCount: null,
    extraBowlScoreSymbol: " ",
  };
}

export function deepClone(frame: Frame): Frame {
  return {
    ...frame,
    hitPinCounts: [...frame.hitPinCounts],
    bowlScoreSymbols: [...frame.bowlScoreSymbols],
  };
}

export function castToFinalFrame(frame: Frame): FinalFrame | undefined {
  const finalFrame = deepClone(frame) as FinalFrame;
  return finalFrame?.extraHitPinCount === undefined ? undefined : finalFrame;
}

export function getBowlIndex(frame: Frame): 0 | 1 | 2 | null {
  if (frame.hitPinCounts[0] === null) {
    return 0;
  }
  if (frame.hitPinCounts[1] === null) {
    return 1;
  }
  const finalFrame = castToFinalFrame(frame);
  if (finalFrame && finalFrame.extraHitPinCount === null) {
    return 2;
  }
  return null;
}

export function scoredSpareOrStrike(frame: Frame): boolean {
  // If this is a final frame
  if (castToFinalFrame(frame)) {
    throw new Error(
      "Cannot determine whether final frame scored a spare or strike; meaning is ambiguous",
    );
  }
  // Return whether the score of the first 2 bowls is 10
  return (frame.hitPinCounts[0] ?? 0) + (frame.hitPinCounts[1] ?? 0) === 10;
}

export function scoredSpare(frame: Frame): boolean {
  // If this is a final frame
  if (castToFinalFrame(frame)) {
    throw new Error(
      "Cannot determine whether final frame scored a spare; meaning is ambiguous",
    );
  }
  // Check that 10 pins were hit, but not all in the first bowl
  return scoredSpareOrStrike(frame) && (frame.hitPinCounts[0] ?? 0) < 10;
}

export function scoredStrike(frame: Frame): boolean {
  // If this is a final frame
  if (castToFinalFrame(frame)) {
    throw new Error(
      "Cannot determine whether final frame scored a strike; meaning is ambiguous",
    );
  }
  // Check that all 10 pins were hit in the first bowl
  return (frame.hitPinCounts[0] ?? 0) === 10;
}
