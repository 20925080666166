import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import styles from "./App.module.css";
import BowlingRouter from "./Bowling/Router";
import ChargerRouter from "./Charger/Router";
import { Demo } from "./Demo";
import HomeLayout from "./HomeLayout";

const demos: Demo[] = [
  {
    title: "Car Charge Manager",
    img: "img/charger/tesla.png",
    relativeURL: "/charger",
    Component: ChargerRouter,
  },
  {
    title: "Bowling",
    img: "img/bowling/cover.jpg",
    relativeURL: "/bowling",
    Component: BowlingRouter,
  },
];

const routes = [
  <Route key={0} path="/" element={<HomeLayout demos={demos} />} />,
  ...demos.map((demo: Demo, index: number) => (
    <Route
      key={index + 1}
      path={`${demo.relativeURL}/*`}
      element={<demo.Component />}
    />
  )),
  <Route
    key={demos.length + 1}
    path="/*"
    element={<Navigate replace to="" />}
  />,
];

function App() {
  return (
    <div className={styles.app}>
      <BrowserRouter basename="">
        <Routes>{routes}</Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
